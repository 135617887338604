<template>
  <b-modal id="beneficio" title="Beneficio" size="md" variant="dark" :visible="showModal" :hide-header="true"
    :hide-footer="true" @hide="hideModal">
    <div v-if="registro">
      <div>
        <ValidationObserver v-slot="{ handleSubmit, invalid }">
          <form @submit.prevent="handleSubmit(aplicar)">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <h4><b>Escolha o beneficio</b></h4>
                  <h6><b>Beneficios utilizados: {{ registro.totalBeneficiosUtilizados }}</b></h6>
                  <select class="form-control shadow-none" v-model="beneficio">
                    <option :value="undefined">Selecione...</option>
                    <option v-for="(item, index) in registro.beneficiosPlano" :key="'beneficio' + index" :value="item">
                      {{ item.nome }} | {{ item.qtdUtilizacoes }} utilização</option>
                  </select>
                </div>
              </div>
            </div>
            <hr class="mt-3 mb-3" />
            <div class="row">
              <div class="col-6">
                <button class="btn btn-success btn-block" type="submit" :disabled="loading || invalid">
                  <span v-if="loading">Beneficiando...</span>
                  <span v-else>Usar beneficio</span>
                </button>
              </div>
              <div class="col-6">
                <button class="btn btn-danger btn-block" @click.prevent="hideModal()" :disabled="loading">
                  <span>Cancelar</span>
                </button>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>

  </b-modal>
</template>

<script>
import Swal from "sweetalert2";
import auth from "../../auth";
import config from "../../config";
import axios from "axios";
import captureError from "../../helpers/captureError";
export default {
  props: ["registro", "showModal",],
  components: {},
  data() {
    return {
      modificou: false,
      loading: false,
      beneficio: undefined,
    };
  },

  methods: {
    hideModal(arg, data) {
      this.$emit("hide", data || { modificou: this.modificou });
      this.beneficio = undefined;
      this.modificou = false;
    },

    async aplicar() {
      try {
        this.loading = true;

        var result = await Swal.fire({
          text: "Deseja realmente aplicar o uso deste beneficio?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#28a745",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        });

        if (result?.isConfirmed == false || result?.dismiss == "cancel") {
          this.loading = false;
          return;
        }

        var userInfo = await auth.getUserInfo();

        const data = {
          idAssinaturaRegistro: this.registro.idAssinaturaRegistro,
          idRegistro: this.registro.idRegistro,
          idUsuarioAdm: userInfo.id,
          idBeneficioPlano: this.beneficio.id
        };

        await axios.post(
          `${config.API_URLV2}/utilizacaoBeneficioAssinatura/`,
          data
        );

        this.loading = false;

        this.$toast.success("Beneficio aplicado com sucesso ✅");

        this.modificou = true;

        this.hideModal();
      } catch (error) {
        this.loading = false;
        captureError(error);
        this.$toast.error("Não foi possivel Aplicar o beneficio ❌");
      }
    },
  },
};
</script>
<style scoped>
.selected {
  background-color: #4FA935 !important;
}

.card-mensagem {
  cursor: pointer;
  text-align: center;
  color: #FFFFFF;
  padding: 24px;
  background-color: #78849E;
  border-radius: 12px;
}
</style>