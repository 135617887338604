<template>
  <div class="minha-conta" :style="registros.length == 0 ? 'height:100vh' : ''">
    <header class="bg-dark py-2">
      <div class="container">
        <div class="row">
          <div class="col">
            <router-link to="/">
              <img src="/img/logo.svg" width="150" height="34" />
            </router-link>
            <span class="badge badge-success ml-2">admin</span>
          </div>
          <div class="col text-right">
            <SideNav />
          </div>
        </div>
      </div>
    </header>
    <div class="bg-gradient">
      <div class="container text-center pt-3">
        <h3>Beneficios</h3>
        <div class="h-line" style="width:142px;"></div>
      </div>
    </div>

    <div class="container mt-5 pb-5">
      <b-card class="mb-5 my-card">
        <div class="row my-3 mx-1">
          <div class="col-md-4 mb-2">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">
                  Telefone
                </span>
              </div>
              <input type="text" class="form-control" v-mask="['(##) ####-####', '(##) #####-####']"
                v-model="telefone" />
            </div>
          </div>
          <div class="col-md-4 mb-2">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">
                  CPF
                </span>
              </div>
              <input type="text" class="form-control" v-mask="'###.###.###-##'" v-model="cpf" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">
                  Email
                </span>
              </div>
              <input type="text" class="form-control" v-model="email" />
            </div>
          </div>

          <div class="col-md-4 ">
            <button type="submit" class="btn btn-success btn-block " @click.prevent="buscar()" :disabled="loading">
              <span v-if="!loading">Buscar</span>
              <span v-if="loading">Por favor aguarde...</span>
            </button>
          </div>
        </div>
      </b-card>

      <div class="row">
        <div class="col-lg-12 order-lg-2">
          <div class="text-center" v-if="registros.length == 0 && !loading">
            <p>Nenhum cliente encontrado com a busca realizada.</p>
          </div>
          <div v-if="!loading">
            <b-card no-body class="mb-3 my-card" v-for="(registro, index) in registros" :key="'registro' + index">
              <div class="row" style="padding-top:20px;padding-left:20px">
                <div class="col-3">
                  <p><b>Nome:</b> {{ registro.nomeCliente }}</p>
                  <p><b>Plano contratado:</b> {{ registro.nomePlano }}</p>

                </div>
                <div class="col-3">
                  <p><b>Marca Bike: </b> {{ registro.marcaRegistro }}</p>
                  <p><b>Modelo bike:</b> {{ registro.modeloRegistro }}</p>
                </div>
                <div class="col-3">
                  <p><b>Status Assinatura: </b> {{ statusAssinatura(registro.statusAssinatura) }}</p>
                  <p><b>Data Cancelado: </b> {{ formatacaoData.formatarDataLocalsemUtc(registro.dataCancelado) }}</p>
                </div>
                <div class="col-3">
                  <p><b>Data Contratado: </b> {{ formatacaoData.formatarDataLocalsemUtc(registro.dataContratacao) }}</p>
                  <p><b>Dias corridos: </b> {{ registro.diasCorridos }} dias</p>
                </div>
              </div>
              <div
                v-if="!registro.atingiuLimiteBeneficio && registro.statusAssinatura == config.STATUS_ASSINATURA_ATIVA"
                class="row mb-3" style="padding-top:20px;padding-left:20px">
                <div class="col-3">
                  <button type="submit" class="btn btn-success" @click.prevent="escolherBeneficio(registro)"
                    :disabled="loading">
                    <span v-if="!loading">Conceder beneficio</span>
                    <span v-if="loading">Por favor aguarde...</span>
                  </button>
                </div>

                <div class="col-3">
                  <button type="submit" class="btn btn-danger" @click.prevent="abrirModalCancelarRegistro(registro)"
                    :disabled="loading">
                    <span v-if="!loading">Cancelar Assinatura</span>
                    <span v-if="loading">Por favor aguarde...</span>
                  </button>
                </div>

              </div>
              <div v-if="registro.atingiuLimiteBeneficio">
                <div class="col-12">
                  <p><b>Todos os beneficios disponiveis para o plano foram utilizados</b></p>
                </div>
              </div>
            </b-card>
          </div>
        </div>
      </div>
    </div>
    <Loading :criandoSeguro="true" v-if="loading" />
    <ModalBeneficio :registro="registro" :showModal="showModal" @hide="hideModal" />
    <ModalCancelarRegistro :registro="registro" :showModal="showModalCancelar" @hide="hideModalCancelar" />
  </div>
</template>

<script>
import SideNav from "../../components/Admin/SideNav";
import ModalBeneficio from "../../components/Admin/ModalBeneficio";
import ModalCancelarRegistro from "../../components/Admin/ModalCancelarRegistro";
import config from "../../config";
import auth from "../../auth";
import Swal from "sweetalert2";
import axios from "axios";
import captureError from "../../helpers/captureError";
import helper from "../../helpers/helper";
import Loading from "../../components/Usuario/Loading.vue";
import formatacaoData from "../../helpers/formatacaoData";

export default {
  components: {
    SideNav,
    ModalBeneficio,
    ModalCancelarRegistro,
    Loading
  },
  data() {
    return {
      config,
      loading: false,
      registros: [],
      formatacaoData,
      email: null,
      telefone: null,
      cpf: null,
      idUsuarioAdm: undefined,
      helper,
      registro: undefined,
      showModal: false,
      showModalCancelar: false,
      coresBeneficios: {},
    };
  },
  created() {
    var userInfo = auth.getUserInfo();
    if (userInfo && userInfo.cargo) {
      this.idUsuarioAdm = userInfo.id;
    } else {
      this.$router.replace("/login");
    }
  },
  methods: {
    statusAssinatura(id) {
      if (id == config.STATUS_ASSINATURA_RENOVACAO_PENDENTE) {
        return "Renovação Pendente";
      } else if (id == config.STATUS_ASSINATURA_CANCELADA) {
        return "Cancelada";
      } else if (id == config.STATUS_ASSINATURA_ATIVA) {
        return "Ativa";
      } else {
        return "Desconhecido";
      }
    },

    gerarCorAleatoria() {
      const r = Math.floor(Math.random() * 256);
      const g = Math.floor(Math.random() * 256);
      const b = Math.floor(Math.random() * 256);
      const alpha = 0.2; // Valor entre 0 e 1, sendo 0 totalmente transparente e 1 opaco
      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    },
    gerarCorParaItem(key) {
      // Se já existe uma cor para o item, retorne a cor armazenada
      if (!this.coresBeneficios[key]) {
        this.coresBeneficios[key] = this.gerarCorAleatoria(); // Armazena a cor para esse item
      }
      return this.coresBeneficios[key];
    },
    escolherBeneficio(registro) {
      this.registro = registro;
      this.showModal = true;
    },

    abrirModalCancelarRegistro(registro) {
      this.registro = registro;
      this.registro.idUsuarioAdmin = this.idUsuarioAdm;
      this.showModalCancelar = true;
    },

    hideModal(status) {
      this.showModal = false;
      if (status.modificou) {
        this.buscar();
      }
    },

    hideModalCancelar(status) {
      this.showModalCancelar = false;
      if (status.modificou) {
        this.buscar();
      }
    },

    async buscar() {
      if (!this.email && !this.nome && !this.telefone && !this.cpf) {
        Swal.fire({
          icon: "warning",
          text: "Informe ao menos um filtro!",
        });
        return;
      }

      this.loading = true;

      var data = {
        email: this.email,
        nome: this.nome,
        cpf: this.cpf,
      };

      try {
        var response = await axios.post(
          `${config.API_URLV2}/registro/buscarRegistroCliente`,
          data
        );

        this.registros = response.data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        captureError(error);
        Swal.fire({
          icon: "error",
          text:
            "Não foi possível encontrar o cliente. Tente novamente mais tarde ou contate o suporte.",
        });
      }
    },

  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

.my-card {
  border-radius: 12px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  color: #252422;
  margin-bottom: 20px;
  position: relative;
  border: 0;
}

.minha-conta {
  font-family: "Montserrat";
  background-color: #f4f3ef;
  transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
}

.bg-gradient {
  background: rgb(0, 0, 0);
  background: linear-gradient(180deg,
      rgba(0, 0, 0, 0.14609593837535018) 0%,
      rgba(255, 255, 255, 0) 70%);
}

.icone-principal {
  font-size: 43px;
  color: #28a745;
  background: -webkit-linear-gradient(#76b566, #28a745);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.not-collapsed .when-closed,
.collapsed .when-open {
  display: none;
}
</style>
