<template>
  <b-modal id="modal-cancelar-registro" title="Deseja cancelar a assinatura do registro?" size="md" variant="dark"
    :visible="showModal" @hide="hideModal" :hide-footer="true">
    <div v-if="excluir || downgrade || reembolso">
      <div class="form-group">
        <label>Poderia nos dizer o motivo do cancelamento?</label>
        <select class="form-control shadow-none" v-model="motivo">
          <option :value="undefined">Selecione...</option>
          <option v-for="(item, index) in config.MOTIVOS_CANCELAR_REGISTRO" :key="'motivo_cancelamento_' + index"
            :value="item.value">{{ item.text }}</option>
        </select>
      </div>

      <div class="row mx-0 w-100">
        <div class="col-6">
          <button class="btn btn-danger btn-block" :disabled="verificarBloquearCancelar()" @click="cancelar">
            <span v-if="loadingCancelar">Cancelando...</span>
            <span v-else>Cancelar assinatura</span>
          </button>
        </div>
        <div class="col-6">
          <button class="btn btn-success btn-block" @click="hideModal">
            Manter assinatura
          </button>
        </div>
      </div>

    </div>
    <div v-else>
      <div class="card-mensagem mb-4" @click.prevent="reembolso = false; excluir = false; downgrade = true;">
        <h5>Desejo cancelar a assinatura e manter o registro da bike</h5>
      </div>
      <div class="card-mensagem mb-4" @click.prevent="reembolso = false; excluir = true; downgrade = false;">
        <h5>Desejo cancelar a assinatura e excluir o registro da bike</h5>
      </div>
      <div class="card-mensagem mb-4" @click.prevent="reembolso = true; excluir = false; downgrade = false;">
        <h5>Desejo cancelar a assinatura e reembolsar o cliente</h5>
      </div>
    </div>

  </b-modal>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";
import config from "../../config";
import captureError from "../../helpers/captureError";

export default {
  props: ["showModal", "registro"],
  data() {
    return {
      config,
      loadingCancelar: false,
      motivo: undefined,
      sugestao: undefined,
      excluir: false,
      downgrade: false,
      reembolso: false,
    };
  },
  components: {},
  methods: {
    hideModal(arg, data) {
      this.$emit("hide", data || { modificou: this.modificou });
      this.motivo = undefined;
      this.sugestao = undefined;
      this.excluir = false;
      this.downgrade = false;
      this.reembolso = false;
    },
    async cancelar() {
      this.loadingCancelar = true;

      await this.cancelarRegistro();

      this.loadingCancelar = false;

      this.modificou = true;

      return this.hideModal();
    },
    async cancelarRegistro() {
      if (!this.loadingCancelar) return false;

      try {
        var data = {
          idAssinaturaRegistro: this.registro.idAssinaturaRegistro,
          idUsuarioAdmin: this.registro.idUsuarioAdmin,
          motivo: this.motivo,
        };

        if (this.excluir) {
          await axios.delete(`${config.API_URLV2}/registro/excluirRegistros/${this.registro.idRegistro}/${this.motivo}`);
        }

        if (this.downgrade) {
          await axios.put(`${config.API_URLV2}/assinaturaRegistro/alterarAssinatura`, data);
        }

        if (this.reembolso) {
          await axios.put(`${config.API_URLV2}/assinaturaRegistro/alterarAssinaturaReembolso`, data);
        }

        this.$toast.success("Registro cancelado com sucesso ✅");

        return true;
      } catch (e) {
        captureError(e);
        if (e?.response?.data?.error) {
          this.$toast.error();
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: e?.response?.data?.error,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text:
              "Não foi possível cancelar o seguro. Tente novamente mais tarde ou contate o suporte.",
          });
        }
        return false;
      }
    },
    verificarBloquearCancelar() {
      return (
        this.loadingCancelar || !this.motivo
      );
    },
  },
};
</script>
<style>
.selected {
  background-color: #78849E !important;
}

.card-mensagem {
  cursor: pointer;
  text-align: center;
  color: #FFFFFF;
  padding: 24px;
  background-color: #4FA935;
  border-radius: 12px;
}
</style>
